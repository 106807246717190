// src/pages/SearchPage.js

import React from 'react';
import SearchBox from '../components/SearchBox';
import './SearchPage.css';  // Import the CSS for styling
//commits
const SearchPage = () => (
  <div className="search-page">
    <SearchBox />
  </div>
);

export default SearchPage;
